<template>
  <div
    class="c-app flex-row align-items-center"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-alert
                    dismissible
                    fade
                    :show="showing"
                    :variant="variant"
                    >{{ msg }}</b-alert
                  >
                  <CInput placeholder="Username" v-model="username">
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    v-on:keyup.enter="signin()"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton @click="signin()" color="primary" class="px-4">
                        <b-spinner
                          v-if="loading"
                          variant="light"
                          label="Spinning"
                        ></b-spinner>
                        <!-- <span v-if="loading">Mohon tunggu</span> -->
                        <span v-else>Login</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";
import { setToken } from "../store/utils/auth.js";
import { ipBackend } from "@/ipBackend.js";
import _ from "lodash";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      showing: false,
      loading: false,
      variant: "success",
      msg: "",
    };
  },
  beforeMount() {
    if (localStorage.getItem("token")) {
      this.$router.push({ path: "/admin" });
    }
  },
  methods: {
    signin() {
      let vm = this;
      vm.loading = !false;
      setTimeout(() => {
        vm.loading = !true;
      }, 4000);
      axios
        .post(ipBackend + "user/login", {
          username: vm.username,
          password: vm.password,
        })
        .then((res) => {
          console.log(res.data.role, "login");
          if (res.data.message == "sukses") {
            setToken(res.data.token);
            if (res.data.role) {
              if (res.data.role.includes("admin")) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("role", res.data.role);
                vm.$router.push({ path: "/admin" });
              } else {
                this.$store.commit("set_alert", {
                  variant: "success",
                  msg: "TIDAK MEMILIKI AKSES",
                  showing: true,
                });
              }
            } else {
              this.$store.commit("set_alert", {
                variant: "success",
                msg: "TIDAK MEMILIKI AKSES",
                showing: true,
              });
            }
          } else {
            this.$store.commit("set_alert", {
              variant: "success",
              msg:_.toUpper(res.data.message) ,
              showing: true,
            });
          }
        })
        .catch((err) => {
          this.$store.commit("set_alert", {
              variant: "success",
              msg:_.toUpper(err) ,
              showing: true,
            })
        })
    }
  },
};
</script>
